@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('./resources/roboto-v20-latin-regular.woff2') format('woff2'),
    url('./resources/roboto-v20-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('./resources/roboto-v20-latin-700.woff2') format('woff2'),
    url('./resources/roboto-v20-latin-700.woff') format('woff');
}

body {
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #2b2b2b;
}

html {
  height: 100%;
  font-size: 10px;
}
@media screen and (min-width: 400px) {
  html {
    font-size: calc(10px + (14 - 10) * ((100vw - 400px) / (1400 - 400)));
  }
}
@media screen and (min-width: 1400px) {
  html {
    font-size: 14px;
  }
}

#root {
  height: 100%;
}
